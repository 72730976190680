import React from "react";
import Moment from "react-moment";

const HtmlToReactParser = require("html-to-react").Parser;
const htmlToReactParser = new HtmlToReactParser();

export default function Article({ item, orgname, handleClickArticle }) {
  // let image = "images/no-image-placeholder.png";
  let image = process.env.PUBLIC_URL + "/images/no-image-placeholder.png";
  if (item?.draft?.Featured_Photo) {
    image = item?.draft?.Featured_Photo;
  }

  return (
    <div
      className="border rounded-md bg-white text-gray-700 cursor-pointer shadow transition-all hover:shadow-lg hover:scale-[1.02] delay-75 relative pb-8"
      onClick={() => handleClickArticle(item?._id)}
    >
      <div className="flex flex-col flex-start items-start px-4 py-2 font-medium">
        <p className="text-lg w-full truncate text-black">
          {item?.org_name ?? orgname}
        </p>
        <p className="text-sm text-gray-500">
          <span>Published on&nbsp;</span>
          <Moment format="DD-MMM-YYYY">{item?.publishedAt}</Moment>
        </p>
      </div>

      <div className="px-2">
        <img
          className="h-60 w-full object-cover rounded"
          src={image}
          alt="Article Featurephoto"
        />
      </div>

      <div className="p-3">
        <p className="text-lg text-black max-h-14 line-clamp-2 font-medium leading-5">
          {item?.draft?.Title}
        </p>
        <div className="w-full">
          {item?.draft?.Content?.length > 100 ? (
            <>
              <p className="text-sm text-gray-600 break-words line-clamp-2 mt-1">
                {htmlToReactParser.parse(
                  item?.draft?.Content?.slice(0, 100).trim()
                )}
              </p>
              <button className="transition-all border border-blue-600 text-blue-600 hover:bg-blue-600 text-sm rounded-sm hover:text-white px-2 py-1 absolute bottom-2 right-2">
                Read more
              </button>
            </>
          ) : (
            <p className="text-sm text-gray-600 break-words ">
              {htmlToReactParser.parse(item?.draft?.Content)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
