import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Heading from "./insert/Heading";

const custom_config = {
  // plugins: [Base64UploadAdapter ],
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "blockQuote",
      "insertTable",
      "|",
      // 'imageUpload',
      "undo",
      "redo",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
};

function Editter({ txt, index, handleText, s, f, t, st }) {
  const HtmlToReactParser = require("html-to-react").Parser;
  const htmlToReactParser = new HtmlToReactParser();
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState("");
  const [currentEditor, setCurrentEditor] = useState(null);

  useEffect(() => {
    // console.log('global toggle:::;', t)
    if (t) {
      setToggle(false);
    }
  }, [t]);

  const handleDivblur = () => {
    if (t) {
      setToggle(false);
    } else {
      setToggle(!toggle);
    }
    if (currentEditor) {
      let data = currentEditor.getData();
      handleText(index, data);
    }
    // st(false)
    //handle()
  };

  return (
    <>
      <div className="w-[90%]">
        {toggle ? (
          <div className="flex">
            <CKEditor
              required
              editor={ClassicEditor}
              config={custom_config}
              data={txt}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                setCurrentEditor(editor);
              }}
              // onBlur={handle}
              onFocus={(event, editor) => {
                // console.log('Focus.', editor);
              }}
            />
            <p
              className="pl-4 text-blue-500 hover:text-blue-900 cursor-pointer"
              onClick={handleDivblur}
            >
              <b>Save</b>
            </p>
          </div>
        ) : (
          <div onClick={() => setToggle(!toggle)}>
            <Heading txt={txt} hs={s} hf={f} />
          </div>
        )}
      </div>
    </>
  );
}

export default Editter;
