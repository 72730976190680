import React, { useState } from "react";
import { SketchPicker } from "react-color";

function EmailSettings({
  setEmailSubject,
  emailSubject,
  style,
  setStyle,
  update,
  template,
}) {
  console.log();
  const [pickerB, setPickerB] = useState(false);
  const [pickerBg, setPickerBg] = useState(false);
  return (
    <div className="px-2 text-gray-700 h-[64vh] overflow-y-auto">
      <div className="mb-3">
        <p>Email Subject Line :</p>
        <input
          type="text"
          className="border rounded-sm border-gray-300  px-2 py-1  my-1 w-full"
          onChange={(e) => setEmailSubject(e.target.value)}
          value={emailSubject}
          onBlur={() => update(template)}
        />
      </div>
      {/* <div className='mb-3'>
                <p>Preheader</p>
                <input type='text' className='border rounded-sm border-gray-300 px-2 py-1  my-1 w-full' />
            </div> */}
      <div className="mb-3">
        <label className="block mb-2">Heading Font:</label>
        <select
          className=" border border-gray-300 text-gray-700 text-sm
                rounded-sm w-full px-2 py-2 bg-white"
          onChange={(e) => setStyle({ ...style, hf: e.target.value })}
        >
          <option value="Arial" selected={style.hf === "Arial"}>
            {" "}
            Arial
          </option>
          <option value="Courier" selected={style.hf === "Courier"}>
            Courier
          </option>
          <option value="Georgia" selected={style.hf === "Georgia"}>
            Georgia
          </option>
        </select>
      </div>
      <div className="mb-3">
        <label className="block mb-2">Heading Font Size:</label>
        <select
          className=" border border-gray-300
                 text-gray-700 text-sm rounded-sm w-full px-2 py-2 bg-white"
          onChange={(e) => setStyle({ ...style, hs: e.target.value })}
        >
          <option value="24px" selected={style.hs === "24px"}>
            small
          </option>
          <option value="28px" selected={style.hs === "28px"}>
            Medium
          </option>
          <option value="32px" selected={style.hs === "32px"}>
            Large
          </option>
        </select>
      </div>
      <div className="mb-3">
        <label className="block mb-2 ">Content Font:</label>
        <select
          className=" border border-gray-300 text-gray-700 text-sm
                 rounded-sm w-full px-2 py-2 bg-white"
          onChange={(e) => setStyle({ ...style, tf: e.target.value })}
        >
          <option value="Arial" selected={style.tf === "Arial"}>
            {" "}
            Arial
          </option>
          <option value="Courier" selected={style.tf === "Courier"}>
            Courier
          </option>
          <option value="Georgia" selected={style.tf === "Georgia"}>
            Georgia
          </option>
        </select>
      </div>
      <div className="mb-3">
        <label className="block mb-2">Content Font Size:</label>
        <select
          className=" border border-gray-300 text-gray-700
                 text-sm rounded-sm w-full px-2 py-2 bg-white"
          onChange={(e) => setStyle({ ...style, ts: e.target.value })}
        >
          <option value="16px" selected={style.ts === "16px"}>
            Small
          </option>
          <option value="20px" selected={style.ts === "20px"}>
            Medium
          </option>
          <option value="24px" selected={style.ts === "24px"}>
            Large
          </option>
        </select>
      </div>
      <div className="mb-3">
        <label className="block mb-2 "> Background Color:</label>
        <div
          className="h-[50px] w-[50px] ml-3 mb-3"
          style={{ background: `${style.bg}` }}
          onClick={() => setPickerBg(!pickerBg)}
        ></div>
        {pickerBg && (
          <SketchPicker
            color={style.bg}
            onChangeComplete={(e) => setStyle({ ...style, bg: e.hex })}
          />
        )}
      </div>
      <div className="mb-3">
        <label className="block mb-2"> Button Color:</label>
        <div
          className="h-[50px] w-[50px] ml-3 mb-3"
          style={{ background: `${style.bbg}` }}
          onClick={() => setPickerB(!pickerB)}
        ></div>
        {pickerB && (
          <SketchPicker
            color={style.bg}
            onChangeComplete={(e) => setStyle({ ...style, bbg: e.hex })}
          />
        )}
      </div>
    </div>
  );
}

export default EmailSettings;
