import React, { useState, useEffect, useContext } from "react";
// import DateTimePicker from 'react-datetime-picker';
import { DatePicker as DateTimePicker, Button } from "antd";
import { getItem } from "../../utils/storage";
import { OrgContext } from "../../OrgContext";
import { getReqParamheader } from "../../services/apiCall";
import { API_URL_CONSTANT } from "../../constants/apiConstant";
import { useLocation } from "react-router-dom";

const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

// Load the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

function SentSettings({
  send,
  sendTest,
  email,
  setEmail,
  contact,
  sub,
  setSub,
  scheduleEmail,
  sendEmailCustom,
  scheduleEmailCustom,
  isDisabledSchedule,
  isDisabledTestEmail,
  isDisabledSendMail,
  msg,
}) {
  const [data, setData] = useState({
    Event_End_Time: dayjs(),
    scheduledTime: dayjs(),
  });
  const [list, setList] = useState([]);
  const [selectedCustom, setSelectedCustom] = useState([]);
  const [toggleSchedule, setToggleSchedule] = useState(false);
  const [customListCount, setCustomListCount] = useState(0);
  const { organizationId } = useContext(OrgContext);
  const path = useLocation().pathname;

  const isSaving = msg === "Your work is saving...";

  useEffect(() => {
    getContact();
  }, []);

  const getContact = () => {
    let userId = getItem("user_id");
    let currentorg = organizationId;
    if (currentorg.length == 0) {
      let orgid = path.split("/")[2];
      currentorg = orgid;
    }
    let url = `${API_URL_CONSTANT.baseUrl}/customlist_details/${currentorg}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('get custom contact details response', res?.data)
          setList(res?.data?.data);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const sendMail = () => {
    if (selectedCustom.length > 0) {
      sendEmailCustom(selectedCustom);
    } else {
      send();
    }
  };

  const scheduleMail = (data) => {
    if (selectedCustom.length > 0) {
      delete data["Event_End_Time"];
      scheduleEmailCustom(
        selectedCustom,
        parseDateToPDTDate(data, "scheduledTime")
      );
    } else {
      delete data["scheduledTime"];
      scheduleEmail(parseDateToPDTDate(data, "Event_End_Time"));
    }
  };

  const getContactCount = (customlistname, operation) => {
    let userId = getItem("user_id");
    let currentorg = organizationId;
    if (currentorg.length == 0) {
      let orgid = path.split("/")[2];
      currentorg = orgid;
    }
    let url = `${API_URL_CONSTANT.baseUrl}/estimated_count/${currentorg}/${userId}?customlist=${customlistname}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('get custom contact details response', res?.data)
          let val = customListCount;
          if (operation == true) {
            val = val + res?.data?.data;
          } else {
            val = val - res?.data?.data;
          }
          setCustomListCount(val);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const parseDateToPDTDate = (payload, dateKey) => {
    const dayjsDate = payload[dateKey];
    const utcDate = dayjsDate.format("YYYY-MM-DDTHH:mm:ss");
    // Set the timezone to Pacific Daylight Time (PDT)
    const pdtDate = dayjs
      .tz(utcDate, "America/Los_Angeles")
      .format("YYYY-MM-DDTHH:mm:ssZ");
    return { ...payload, [dateKey]: pdtDate };
  };

  const disabledDate = (current) => {
    // Can not select days before today and after 3 days from today
    const endDate = dayjs().add(3, "day").endOf("day");
    return current && (current < dayjs().startOf("day") || current > endDate);
  };

  const onChangeScheduleMailDate = (date) => {
    setData({ ...data, Event_End_Time: date, scheduledTime: date });
  };

  return (
    <div className="px-2 h-[64vh] overflow-y-auto">
      <p className="text-bold text-black text-xl font-medium"> Send a Test</p>
      <p className="text-small my-1">Test Email (Comma Separated):</p>
      <input
        type="email"
        placeholder="Enter Email"
        className="border rounded-sm p-2 my-2 w-full"
        onChange={(e) =>
          setEmail(e.target.value ? e.target.value.toLowerCase() : "")
        }
        value={email}
      />
      <div className="mt-1 mb-3">
        <Button
          className="bg-white"
          loading={isDisabledTestEmail}
          disabled={isDisabledTestEmail || isSaving}
          onClick={sendTest}
        >
          Send Test
        </Button>
      </div>
      <p className="text-bold text-black text-2xl"> Send Email</p>
      <div className="py-2 cursor-pointer">
        <input
          id="active_sub"
          type="checkbox"
          checked={sub}
          onChange={(e) => {
            setSub(e.target.checked);
            if (e.target.checked == true) {
              setSelectedCustom([]); //unset custom in this case
            }

            // console.log(e.target.checked)
          }}
        />
        <label htmlFor="active_sub"> All Active Subscribers</label>
      </div>
      <div>
        <div className="py-2">
          <p>Custom Lists:</p>
          {list && list.length > 0 ? (
            <>
              {list.map((item, i) => (
                <div key={i} className="cursor-pointer">
                  <input
                    style={{ margin: "4px" }}
                    type="checkbox"
                    id={item?.Team_Group_Name}
                    onChange={(e) => {
                      if (e.target.checked) {
                        //add text here
                        let temparr = selectedCustom;
                        temparr.push(e.target.labels[0].outerText);
                        setSelectedCustom(temparr);
                        setSub(false); //reset active
                        //Call api here to add to list number
                        getContactCount(e.target.labels[0].outerText, true);
                      } else {
                        //remove here
                        let temparr = selectedCustom;
                        let idxtoremove = temparr.indexOf(
                          e.target.labels[0].outerText
                        );
                        if (idxtoremove > -1) {
                          temparr.splice(idxtoremove, 1);
                          setSelectedCustom(temparr);
                          //Call api here to remove from list number
                          getContactCount(e.target.labels[0].outerText, false);
                        }
                      }
                    }}
                  />
                  <label htmlFor={item?.Team_Group_Name}>
                    {item?.Team_Group_Name}
                  </label>
                </div>
              ))}
            </>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <p className="border-b-2 pb-4">
        {" "}
        Estimated Send Count:{" "}
        {selectedCustom.length > 0
          ? customListCount
          : sub
          ? contact?.length
          : 0}
      </p>
      {
        <div className="my-3">
          <label style={{ marginBottom: "10px" }}>
            <input
              style={{ margin: "4px" }}
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  setToggleSchedule(true);
                } else {
                  setToggleSchedule(false);
                }
              }}
            />
            Do you want to schedule this email?
          </label>
          {!toggleSchedule ? (
            <Button
              className="bg-white ml-4"
              loading={isDisabledSendMail}
              disabled={isDisabledSendMail || isSaving}
              onClick={sendMail}
            >
              Send Email
            </Button>
          ) : (
            <div className="mt-3">
              {/* value=data?.Event_End_Time */}
              <DateTimePicker
                format="YYYY-MM-DD hh:mm A"
                showTime={{ format: "hh:mm a" }}
                disabledDate={disabledDate}
                onChange={onChangeScheduleMailDate}
              />
              <Button
                className="bg-white mt-2"
                disabled={isDisabledSchedule || isSaving}
                loading={isDisabledSchedule}
                onClick={() => scheduleMail(data)}
              >
                Schedule Email
              </Button>
            </div>
          )}
        </div>
        // :
        // <p className='py-3'> You can send or schedule this email once you have chosen which contacts you would like to send it to.</p>
      }
    </div>
  );
}

export default SentSettings;
