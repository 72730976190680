import React from "react";
function BaseTabs({ active, handleTab, tabList }) {
  return (
    <>
      <div className="w-full flex justify-between gap-2 mb-2 border-b rounded-sm cursor-pointer">
        {tabList.map((tab, i) => (
          <div
            className={
              active === tab
                ? "active-tab w-full text-center pb-2 font-medium bg-white px-1"
                : "w-full text-center pb-2 hover:text-blue-500 px-1"
            }
            onClick={() => handleTab(tab)}
            key={i}
          >
            {tab}
          </div>
        ))}
      </div>
    </>
  );
}

export default BaseTabs;
