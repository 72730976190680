import React, { useState, useEffect, useMemo, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { ToastContainer, toast } from "react-toastify";
import BaseTabs from "./BaseTabs";
import InsertContents from "./email/InsertContents";
import EmailSettings from "./email/EmailSettings";
import SentSettings from "./email/SentSettings";
import Editter from "./email/Editter";
import DefaultButton from "./email/DefaultButton";
import { Setting } from "../assests/icons/email/BaseIcon";
import DefaultFoolter from "./email/DefaultFoolter";
import DefaultLogo from "./email/DefaultLogo";
import DefaultArtical from "./email/DefaultArtical";
import { getItem } from "../utils/storage";
import Heading from "./email/insert/Heading";
import ModiificationCard from "./email/ModiificationCard";
import Divider from "./email/insert/Divider";
import {
  getReqParamheader,
  postReqParamheader,
  patchReqParam,
  postReqParamheaderFile,
} from "../services/apiCall";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import OrgLogo from "./email/OrgLogo";
import ArticleComponent from "./email/ArticleComponent";
import EventDigest from "./email/EventDigest";
import moment from "moment";

import { Dropdown } from "antd";

function EmailTemplate() {
  const [activeTab, setActiveTab] = useState("Insert Content");
  const [msg, setMsg] = useState("Your work is saved");
  const [template, setTemplate] = useState([]);
  const [orgData, setOrgData] = useState({});
  const [review, setReview] = useState(false);
  const [emailList, setEmailList] = useState("");
  const [emailId, setEmailId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [sub, setSub] = useState(false);
  const [gt, setgt] = useState(false);
  const [emailSubject, setEmailSubject] = useState("Sample Email Subject");
  const [itemsImgArray, setItemsImgArray] = useState({});
  const [commonStyle, setCommonStyle] = useState({
    bg: "#FFFFFF",
    bbg: "#0000FF",
    ts: "16px",
    tf: "Arial",
    hs: "24px",
    hf: "Arial",
  });
  const [contact, setContact] = useState([]);
  const [isModified, setIsModified] = useState({ show: false, index: -1 });
  const [org, setOrg] = useState("");
  const [userId, setUserId] = useState("");
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const count = useRef(0);
  const [readMore, setReadMore] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [logoDimensions, setLogoDimensions] = useState({
    width: 0,
    height: 0,
  });

  const viewInBrowser = `
  <a href="https://smorgborg.org/email/view/${encodeURIComponent(emailId)}" 
     style="text-align:left; text-decoration: none;" 
     target="_blank" 
     rel="noopener noreferrer">
      View in Browser
  </a>
  `;

  useEffect(() => {
    let userid = getItem("user_id");
    let orgid = path.split("/")[2];
    setOrg(orgid);
    setUserId(userid);
    let email = path.split("/")[4];
    if (email !== "create") {
      setEmailId(email);
      setIsEdit(true);
    }
    if (email === "create") {
      // setArticleId(article)
      setIsEdit(false);
    }
    if (org) {
      getCurrentOrg(org);
    }
  }, []);
  useEffect(() => {
    if (emailId && isEdit) {
      getByIdEmail(emailId);
    }
    if (org) {
      getCurrentOrg(org);
      getContact();
    }
  }, [emailId, org]);

  const processLogoImg = async (logo) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = logo;
      img.onload = () => {
        let aratio = (img.height / img.width).toFixed(2);
        let w = img.width > 600 ? 600 : img.width;
        let h = img.width > 600 ? 600 * aratio : img.height;
        console.log(w, h);
        setLogoDimensions({ height: h.toString(), width: w.toString() });
        resolve({ w, h });
      };
    });
  };

  async function fetchLogoDimesions(logo) {
    const response = await processLogoImg(logo);
    console.log(response);
  }

  const handleTab = (active) => {
    setActiveTab(active);
  };
  const hanleText = (id, updatedtext) => {
    let arr = template.map((item, i) =>
      i === id && updatedtext ? { ...item, txt: updatedtext } : item
    );
    setTemplate(arr);
    count.current = count.current + 1;
  };
  // const addDefultLogo = (link) => {
  //   let logo = {
  //     id: Math.round(new Date().valueOf() / 1000),
  //     name: "Logo",
  //     // icon: <Photo />,
  //     type: "image",
  //     style: {
  //       logoUrl: link
  //         ? link
  //         : orgData?.Website_link
  //         ? orgData?.Website_link
  //         : "https://www.smorgborg.org/",
  //     },
  //   };

  //   if (template?.length < 1) {
  //     handleInsert(logo);
  //   }
  // };

  const hanleDynamicStyle = (style, id) => {
    let arr = template.map((item, i) =>
      i === id && style ? { ...item, style: style } : item
    );
    // template=arr
    setTemplate(arr);
    //count.current = count.current + 1;
    // handleAutoSave()
    // exportHTML()
  };
  const handleInsert = (action) => {
    // template.push(action);
    setTemplate((prevTemplate) => [...prevTemplate, { ...action }]);
    exportHTML(template);
  };

  const getCurrentOrg = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/Organization_details/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('Org details:', res?.data)
          setOrgData(res?.data?.data);
          if (res?.data?.data?.primary_email) {
            //setEmailList(res?.data?.data?.primary_email)
            let curuser = JSON.parse(getItem("user"));
            setEmailList(curuser.email);
          }
          fetchLogoDimesions(res?.data?.data?.logo);
          // if (template?.length < 1 && !isEdit) {
          //   addDefultLogo(res?.data?.data?.Website_link);
          // }
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const nofunction = () => {
    //do nothing
  };

  const Template = () => {
    return (
      <div
        style={{
          background: `${commonStyle.bg}`,
          padding: "0px 8% 8%",
          color: "black",
        }}
      >
        <div style={{ background: "#ffffff", color: "black" }}>
          <OrgLogo
            url={orgData?.Website_link}
            logo={orgData?.logo}
            orgName={orgData?.OrganizationName}
            save={nofunction}
            logow={logoDimensions?.width}
            logoh={logoDimensions?.height}
          />
          {template.map((item, i) => (
            <div
              key={i}
              style={{
                margin: "0px 16px",
                paddingTop: i === 0 ? "12px" : "0px",
                // textAlign: "left",
              }}
            >
              {item.type === "text" ? (
                <div style={{ paddingBottom: "6px", textAlign: "start" }}>
                  <Heading
                    txt={item.txt}
                    hs={commonStyle.ts}
                    hf={commonStyle.tf}
                  />
                </div>
              ) : item.type === "textH" ? (
                <div style={{ paddingBottom: "6px", textAlign: "start" }}>
                  <Heading
                    txt={item.txt}
                    hs={commonStyle.hs}
                    hf={commonStyle.hf}
                  />
                </div>
              ) : item.type === "image" ? (
                <div style={{ paddingBottom: "6px" }}>
                  <OrgLogo
                    url={item?.style.logoUrl}
                    orgName={orgData?.OrganizationName}
                    logo={orgData?.logo}
                    logow={logoDimensions?.width}
                    logoh={logoDimensions?.height}
                  />
                </div>
              ) : item?.type === "Photo" ? (
                <div>
                  <DefaultLogo type="Photo" url={item?.data} path={path} />
                </div>
              ) : item.type === "btn" ? (
                <div style={{ paddingBottom: "6px" }}>
                  <DefaultButton
                    bg={commonStyle.bbg}
                    txt={item?.style.btnText}
                    align={item?.style.btnAlign}
                    link={item?.style.btnLink}
                    btnSize={item?.style.btnSize}
                  />
                </div>
              ) : item.type === "hline" ? (
                <div style={{ paddingBottom: "6px", width: "100%" }}>
                  <Divider />
                </div>
              ) : item.type === "space" ? (
                <div>
                  <br />
                </div>
              ) : item.type === "eventdigest" ? (
                <EventDigest
                  data={item?.data}
                  type="eventdigest"
                  id={item?.data?._id}
                  ts={commonStyle.ts}
                  tf={commonStyle.tf}
                  hs={commonStyle.hs}
                  hf={commonStyle.hf}
                />
              ) : item.type === "event" ? (
                <ArticleComponent
                  data={item?.data}
                  type="event"
                  ts={commonStyle.ts}
                  tf={commonStyle.tf}
                  hs={commonStyle.hs}
                  hf={commonStyle.hf}
                  read={item?.read}
                  logow={
                    itemsImgArray[item?.id]
                      ? itemsImgArray[item?.id].width
                      : "600"
                  }
                  logoh={
                    itemsImgArray[item?.id]
                      ? itemsImgArray[item?.id].height
                      : "300"
                  }
                />
              ) : (
                <ArticleComponent
                  data={item?.data?.draft}
                  id={item?.data?._id}
                  ts={commonStyle.ts}
                  tf={commonStyle.tf}
                  hs={commonStyle.hs}
                  hf={commonStyle.hf}
                  read={item?.read}
                  logow={
                    itemsImgArray[item?.id]
                      ? itemsImgArray[item?.id].width
                      : "600"
                  }
                  logoh={
                    itemsImgArray[item?.id]
                      ? itemsImgArray[item?.id].height
                      : "300"
                  }
                />
              )}
            </div>
          ))}
          <DefaultFoolter orgData={orgData} user={userId} />
        </div>
      </div>
    );
  };

  const updateImgArray = (id, w, h) => {
    if (w & h) {
      let imgdata = itemsImgArray;
      imgdata[id] = { width: w, height: h };
      setItemsImgArray(imgdata);
    }
  };

  const EmailTemplateFn = ({ temp }) => {
    return (
      //style={{ background: `${commonStyle.bg}`, padding: '0px 10% 10%', color: 'black' }}
      <div>
        <div style={{ background: "#ffffff", color: "black" }}>
          <OrgLogo
            url={orgData?.Website_link}
            logo={orgData?.logo}
            orgName={orgData?.OrganizationName}
            save={nofunction}
            logow={logoDimensions?.width}
            logoh={logoDimensions?.height}
          />
          {temp?.map((item, i) => (
            <div
              key={i}
              style={{
                margin: "0px 16px",
                paddingTop: i === 0 ? "12px" : "0px",
              }}
            >
              {item.type === "text" ? (
                <div
                  style={{
                    paddingBottom: "6px",
                    textAlign: "start",
                  }}
                >
                  <Heading
                    txt={item.txt}
                    hs={commonStyle.ts}
                    hf={commonStyle.tf}
                  />
                </div>
              ) : item.type === "textH" ? (
                <div
                  style={{
                    paddingBottom: "6px",
                    textAlign: "start",
                  }}
                >
                  <Heading
                    txt={item.txt}
                    hs={commonStyle.hs}
                    hf={commonStyle.hf}
                  />
                </div>
              ) : item.type === "image" ? (
                <div style={{ paddingBottom: "6px" }}>
                  <OrgLogo
                    url={item?.style.logoUrl}
                    logo={orgData?.logo}
                    orgName={orgData?.OrganizationName}
                    save={nofunction}
                    id={item?.id}
                    logow={
                      itemsImgArray[item?.id]
                        ? itemsImgArray[item?.id].width
                        : "600"
                    }
                    logoh={
                      itemsImgArray[item?.id]
                        ? itemsImgArray[item?.id].height
                        : "300"
                    }
                  />
                </div>
              ) : item?.type === "Photo" ? (
                <div>
                  <a
                    href={item.style.photoUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <DefaultLogo
                      type="Photo"
                      url={item?.data}
                      path={path}
                      save={nofunction}
                      currentid={item?.id}
                      logow={
                        itemsImgArray[item?.id]
                          ? itemsImgArray[item?.id].width
                          : "600"
                      }
                      logoh={
                        itemsImgArray[item?.id]
                          ? itemsImgArray[item?.id].height
                          : "300"
                      }
                    />
                  </a>
                </div>
              ) : item.type === "btn" ? (
                <div style={{ paddingBottom: "6px" }}>
                  <DefaultButton
                    bg={commonStyle.bbg}
                    txt={item?.style.btnText}
                    align={item?.style.btnAlign}
                    link={item?.style.btnLink}
                    btnSize={item?.style.btnSize}
                  />
                </div>
              ) : item.type === "hline" ? (
                <div style={{ paddingBottom: "6px", width: "100%" }}>
                  <Divider />
                </div>
              ) : item.type === "space" ? (
                <div>
                  <br />
                </div>
              ) : item.type === "eventdigest" ? (
                <EventDigest
                  data={item?.data}
                  type="eventdigest"
                  id={item?.data?._id}
                  ts={commonStyle.ts}
                  tf={commonStyle.tf}
                  hs={commonStyle.hs}
                  hf={commonStyle.hf}
                />
              ) : item.type === "event" ? (
                <ArticleComponent
                  data={item?.data}
                  type="event"
                  ts={commonStyle.ts}
                  tf={commonStyle.tf}
                  hs={commonStyle.hs}
                  hf={commonStyle.hf}
                  read={item?.read}
                  logow={
                    itemsImgArray[item?.id]
                      ? itemsImgArray[item?.id].width
                      : "600"
                  }
                  logoh={
                    itemsImgArray[item?.id]
                      ? itemsImgArray[item?.id].height
                      : "300"
                  }
                />
              ) : (
                <ArticleComponent
                  data={item?.data?.draft}
                  id={item?.data?._id}
                  ts={commonStyle.ts}
                  tf={commonStyle.tf}
                  hs={commonStyle.hs}
                  hf={commonStyle.hf}
                  logow={
                    itemsImgArray[item?.id]
                      ? itemsImgArray[item?.id].width
                      : "600"
                  }
                  logoh={
                    itemsImgArray[item?.id]
                      ? itemsImgArray[item?.id].height
                      : "300"
                  }
                  read={item?.read}
                />
              )}
            </div>
          ))}
          <DefaultFoolter orgData={orgData} user={userId} />
        </div>
      </div>
    );
  };
  const preview = () => {
    setReview(!review);
  };

  const generateEmailHTML = (template, commonStyle, body) => {
    return `
      <html>
      <head>
      <style>
        img {
          max-width: 600px !important;
          max-height: 600px !important;
        }
        * {
          margin: 0;
          // text-align: left;
        }
        <!--[if mso]>
        <style type="text/css">
        .buttonClass {
            padding: 8px 8px 16px 16px !important;
        }
        </style>
        <![endif]-->
      </style>
      </head>
      <body>
        <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor=${commonStyle.bg} style="background: ${commonStyle.bg}; text-align: left;">
          <tbody>
            <tr>
              <td>
                <center>
                  <div style="margin: 0 auto; color: black; background: ${commonStyle.bg}; padding-top: 25px; padding-bottom: 25px;">
                    <table width="670" cellpadding="0" cellspacing="0" border="0" style="margin: auto;">
                      <tbody>
                        <tr style="background: white; color: black;">
                          <td width="620" align="center">
                            ${viewInBrowser}
                            ${body}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </center>
              </td>
            </tr>
          </tbody>
        </table>
      </body>
      </html>
    `;
  };

  const cleanTemplate = (template) => {
    template.forEach((item) => {
      if (item.type === "artical" && item.data?.draft?.Content) {
        item.data.draft.Content = item.data.draft.Content.replace(
          /<p>&nbsp;<\/p>/g,
          ""
        );
      }
    });
    return template;
  };

  const exportHTML = (updatedTemplate = template) => {
    // console.log("Updated Template: ", updatedTemplate);
    setMsg("Your work is saving...");

    const safeTemplate = Array.isArray(updatedTemplate) ? updatedTemplate : [];

    let body;
    if (safeTemplate.length > 0) {
      body = ReactDOMServer.renderToString(
        <EmailTemplateFn temp={safeTemplate} />
      );
    }

    const dummy = generateEmailHTML(safeTemplate, commonStyle, body);

    const emailData = {
      Subject: emailSubject,
      Common_style: commonStyle,
      Preview: dummy,
      template: cleanTemplate(safeTemplate),
    };

    if (emailData?.Subject && emailData?.Preview) {
      isEdit ? updateTemplate(emailData) : SaveTemplate(emailData);
    } else {
      alert("Email Subject Cannot Be Empty");
    }
  };

  const SaveTemplate = (data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/createEmail/${org}/${userId}`;
    postReqParamheader(url, data)
      .then((res) => {
        if (res?.status) {
          setEmailId(res?.data?.data?._id);
          setIsEdit(true);
          setMsg("Your work is saved.");
          //toast.success(res?.data?.message)
        } else {
          //console.log('response err', res?.message)
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const updateTemplate = (data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/updateEmail/${emailId}/${org}/${userId}`;
    patchReqParam(url, data)
      .then((res) => {
        if (res?.status) {
          // console.log("update email response", res?.data);
          // toast.success(res?.data?.message)
          // getByIdEmail(emailId);
          setMsg("Your work is saved.");
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  useMemo(() => {
    if (isEdit && emailId) {
      exportHTML(template);
    }
  }, [count.current]);

  const triggerSave = () => {
    count.current = count.current + 1;
  };

  const getByIdEmail = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/emaildetailsbyId/${id}/${org}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log("get email template details by id response", res?.data);
          setCommonStyle(res?.data?.data?.Common_style);
          setEmailSubject(res?.data?.data?.Subject);
          setTemplate(res?.data?.data?.template);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleToSendEmail = (email) => {
    setEmailList(email);
  };
  const handleEmailSub = (sub) => {
    setEmailSubject(sub);
  };
  const handleCommonStyle = (style) => {
    setCommonStyle(style);
    count.current = count.current + 1;
  };
  const handleSendEmail = () => {
    if ((emailList || contact) && emailSubject) {
      sendMail();
      setEmailList("");
    } else {
      alert("Email Subject or list Can Not Be Empty");
    }
  };

  const handleSendTestEmail = () => {
    if ((emailList || contact) && emailSubject) {
      exportHTML(template);
      sendTestMail();
      setEmailList("");
    } else {
      alert("Email Subject or list Can Not Be Empty");
    }
  };

  const sendMail = () => {
    if (emailId && isEdit) {
      let data = {};
      if (emailList) {
        data.sent_email_recipents = emailList;
      }
      if (sub) {
        if (data?.sent_email_recipents) {
          data.sent_email_recipents =
            data?.sent_email_recipents + "," + contact.join(",");
        } else {
          data.sent_email_recipents = contact.join(",");
        }
      }
      sendEmailToUser(data);
    } else {
      alert("Please Save Template First...");
    }
  };

  const sendTestMail = () => {
    if (emailId && isEdit) {
      let data = {};
      if (emailList) {
        data.sent_email_recipents = emailList;
      }
      if (sub) {
        if (data?.sent_email_recipents) {
          data.sent_email_recipents =
            data?.sent_email_recipents + "," + contact.join(",");
        } else {
          data.sent_email_recipents = contact.join(",");
        }
      }
      sendTestEmailToUser(data);
    } else {
      alert("Please Save Template First...");
    }
  };

  const handleScheduleEmail = (dt) => {
    //let fdt = moment(dt.Event_End_Time).format("YYYY-MM-DD hh:mm:ss a");
    let fdt = dt.Event_End_Time;

    let data = {};

    if (emailList) {
      data.sent_email_recipents = emailList;
    }
    if (sub) {
      if (data?.sent_email_recipents) {
        if (contact?.length > 0) {
          data.sent_email_recipents =
            data?.sent_email_recipents + "," + contact.join(",");
        }
      } else {
        data.sent_email_recipents = contact.join(",");
      }
    }

    data.scheduledTime = fdt;

    scheduledEmailApi(data);
    //scheduleToCustomList(fdt)
  };

  const scheduledEmailApi = (data) => {
    let url = `${API_URL_CONSTANT.baseUrl}/sentMailtoaddressbytime/${emailId}/${org}/${userId}`;
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          //  console.log('sent email response', res?.data)
          toast.success(res?.data?.message);
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        } else {
          //console.log('response err', res?.message)
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  const sendEmailToUser = (data) => {
    setIsSendingEmail(true);
    let url = `${API_URL_CONSTANT.baseUrl}/sentMailtoaddress/${emailId}/${org}/${userId}`;
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          //  console.log('sent email response', res?.data)
          toast.success(res?.data?.message);
        } else {
          //console.log('response err', res?.message)
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setIsSendingEmail(false);
      });
  };

  const sendTestEmailToUser = (data) => {
    setIsSendingTestEmail(true);
    let url = `${API_URL_CONSTANT.baseUrl}/senttestMailtoaddress/${emailId}/${org}/${userId}`;
    postReqParamheaderFile(url, data)
      .then((res) => {
        if (res?.status) {
          //  console.log('sent email response', res?.data)
          toast.success(res?.data?.message);
        } else {
          //console.log('response err', res?.message)
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setIsSendingTestEmail(false);
      });
  };

  /*
  const sendToCustomList = (customEmails) => {
    if (customEmails.length > 0) {
      for (let k = 0; k < customEmails.length; k++) {
        let url = `${API_URL_CONSTANT.baseUrl}/sentMailtocustomlist/${emailId}/${org}/${userId}?customlists=${customEmails[k]}`;
        postReqParamheader(url)
          .then((res) => {
            if (res?.status) {
              // console.log('restore article response', res?.data)
              toast.success(res?.data?.message);
            } else {
              //toast.error(err?.response?.data?.message)
              console.log("response err", res?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
            //console.log('err', err)
          });
      }
    } else {
      toast.error("Selected custom list doesn't have enough data.");
    }
  };

  const scheduleToCustomList = (customEmails, data) => {
    if (customEmails.length > 0) {
      //   let fdt = moment(dt.Event_End_Time).format('YYYY-MM-DD') + " " + dt?.Event_End_Time.toLocaleTimeString()
      // data.scheduledTime = fdt
      // let data = customEmails.join(",");
      for (let k = 0; k < customEmails.length; k++) {
        let url = `${API_URL_CONSTANT.baseUrl}/sentMailtocustomlistbyscheduledime/${emailId}/${org}/${userId}?customlists=${customEmails[k]}`;
        postReqParamheader(url, data)
          .then((res) => {
            if (res?.status) {
              console.log("restore article response", res?.data);
              //toast.success(res?.data?.message)
            } else {
              // toast.error(err?.response?.data?.message)
              console.log("response err", res?.message);
            }
          })
          .catch((err) => {
            //toast.error(err?.response?.data?.message)
            console.log("err", err);
          });
      }
    } else {
      toast.error("Selected custom list doesn't have enough data.");
    }
  };
  */

  const handleSendEmailToCustomList = (customLists, ...args) => {
    if (customLists.length > 0) {
      const isEmailScheduled = args.length > 1;
      isEmailScheduled ? setIsScheduling(true) : setIsSendingEmail(true);

      const apiSlug = isEmailScheduled
        ? "sentMailtocustomlistbyscheduledime"
        : "sentMailtocustomlist";
      let postRequests = [];

      let url = `${API_URL_CONSTANT.baseUrl}/${apiSlug}/${emailId}/${org}/${userId}?customLists=${customLists}`;
      postRequests.push(
        isEmailScheduled
          ? postReqParamheader(url, args[0])
          : postReqParamheader(url)
      );

      Promise.all(postRequests).then((res) => {
        isEmailScheduled
          ? toast.success("Emails scheduled")
          : toast.success("Email has been sent successfully");
        setTimeout(() => {
          navigate(-1);
          isEmailScheduled ? setIsScheduling(false) : setIsSendingEmail(false);
        }, 1000);
      });
    } else {
      toast.error("Selected custom list doesn't have enough data.");
    }
  };

  const handleRemove = (id) => {
    let arr = template;
    if (arr.length > 1) {
      arr = arr.filter(function (item, i) {
        return i !== id;
      });
    } else {
      arr = [];
    }
    setTemplate(arr);
    setIsModified({ ...isModified, show: !isModified.show, index: -1 });
    count.current = count.current + 1;
  };
  const swap = (arr, from, to) => {
    arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
    count.current = count.current + 1;
  };
  const handleMoveUp = (id) => {
    swap(template, id, id - 1);
    setIsModified({ ...isModified, show: !isModified.show, index: -1 });
    count.current = count.current + 1;
  };
  const handleMoveDown = (id) => {
    swap(template, id, id + 1);
    setIsModified({ ...isModified, show: !isModified.show, index: -1 });
    count.current = count.current + 1;
  };
  const handleModified = (id) => {
    let show = isModified.show;
    if (show) {
      setIsModified({ ...isModified, show: !isModified.show, index: -1 });
      // setgt(true)
    } else {
      setIsModified({ ...isModified, show: !isModified.show, index: id });
      // setgt(false)
    }
  };
  const getContact = () => {
    // setLoading(true)
    let url = `${API_URL_CONSTANT.baseUrl}/contact_details/${org}/${userId}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('get contact details response', res?.data)
          let filter = res?.data?.data.filter(
            (item) => item?.Status === "Subscribed"
          );
          let arr = [];

          filter &&
            filter.forEach((c) => {
              arr.push(c?.Email);
            });
          // console.log('cccfgg:::;',arr)
          setContact(arr);
          //setLoading(false)
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        // setLoading(false)
        // toast.error(err?.response?.data?.message)
      });
  };

  const setReadMoreArticle = (id, value) => {
    let arr = template.map((item, i) =>
      item.id === id ? { ...item, read: value } : item
    );
    setTemplate(arr);
    setReadMore(value);
  };

  const renderElement = (data, i) => {
    switch (data?.type) {
      case "text":
        return (
          <Editter
            txt={data.txt}
            s={commonStyle.ts}
            f={commonStyle.tf}
            index={i}
            handleText={hanleText}
            t={gt}
            st={setgt}
          />
        );
      case "textH":
        return (
          <Editter
            txt={data.txt}
            s={commonStyle.hs}
            f={commonStyle.hf}
            index={i}
            handleText={hanleText}
            t={gt}
            st={setgt}
          />
        );
      case "btn":
        return (
          <div className="w-[80%]">
            <DefaultButton
              bg={commonStyle.bbg}
              txt={data.style.btnText}
              align={data.style.btnAlign}
              link={data.style.btnLink}
              btnSize={data.style.btnSize}
              index={i}
            />
          </div>
        );

      case "image":
        return (
          <div className={gt ? "w-[50%]" : "w-[80%]"}>
            <OrgLogo
              url={data.style.logoUrl}
              orgName={orgData.OrganizationName}
              logo={orgData.logo}
              id={data.id}
              save={updateImgArray}
              logow={logoDimensions?.width}
              logoh={logoDimensions?.height}
            />
          </div>
        );
      case "space":
        return (
          <div className="w-full">
            <br />
            <br />
          </div>
        );
      case "hline":
        return (
          <div className="w-full">
            <Divider />
          </div>
        );
      case "Photo":
        return (
          <div className={gt ? "w-[50%]" : "w-[80%]"}>
            <a
              href={data.style.photoUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textAlign: "-webkit-center" }}
            >
              <DefaultLogo
                type="Photo"
                link={data.style.photoUrl}
                url={data.data}
                path={path}
                currentid={data.id}
                save={updateImgArray}
              />
            </a>
          </div>
        );
      case "eventdigest":
        return (
          <div className={gt ? "w-[50%]" : "w-[80%]"}>
            <EventDigest
              data={data.data}
              type="eventdigest"
              id={data.data?._id}
              ts={commonStyle.ts}
              tf={commonStyle.tf}
              hs={commonStyle.hs}
              hf={commonStyle.hf}
            />
          </div>
        );
      case "event":
        return (
          <div className={gt ? "w-[50%]" : "w-[80%]"}>
            <DefaultArtical
              data={data.data}
              type="event"
              ts={commonStyle.ts}
              tf={commonStyle.tf}
              hs={commonStyle.hs}
              hf={commonStyle.hf}
              read={data?.read}
              save={updateImgArray}
              currentid={data.id}
            />
          </div>
        );
      default:
        return (
          <div className={`${gt ? "w-[50%]" : "w-[100%]"} p-4`}>
            <DefaultArtical
              data={data.data?.draft}
              id={data.data?._id}
              ts={commonStyle.ts}
              tf={commonStyle.tf}
              hs={commonStyle.hs}
              hf={commonStyle.hf}
              save={updateImgArray}
              read={data.read}
              currentid={data.id}
              tempData={template}
              exportHtml={exportHTML}
            />
          </div>
        );
    }
  };

  const menu = (data, i) => (
    <ModiificationCard
      title={data.name}
      remove={handleRemove}
      n={template.length}
      index={i}
      type={data?.type}
      up={handleMoveUp}
      down={handleMoveDown}
      style={data?.style}
      setStyle={hanleDynamicStyle}
      t={gt}
      st={setgt}
      saveBtn={triggerSave}
      readmore={setReadMoreArticle}
      currentread={data?.read}
      elementid={data?.id}
      close={() => handleModified(i)}
    />
  );

  return (
    <div className="w-full grid grid-cols-3">
      <div className="col-span-2 flex flex-col">
        <ToastContainer />
        {review ? (
          <div className="text-center">
            <p>
              Email in Preview Mode
              <br />
              Cancel preview below to exit
            </p>
            <Template />
          </div>
        ) : (
          <div
            className="relative "
            style={{
              background: `${commonStyle.bg}`,
              padding: "0px 10% 10%",
              color: "black",
            }}
            id="email_template_container"
          >
            <div style={{ background: "#ffffff" }}>
              <OrgLogo
                url={orgData?.Website_link}
                logo={orgData?.logo}
                orgName={orgData?.OrganizationName}
                save={nofunction}
              />
              {template?.length > 0 &&
                template?.map((data, i) => (
                  <div
                    className={`${
                      i === 0 && "pt-3"
                    } mb-4 flex justify-between items-start w-full`}
                    key={i}
                  >
                    {renderElement(data, i)}
                    <Dropdown
                      open={isModified.show && isModified.index === i}
                      dropdownRender={() => menu(data, i)}
                      getPopupContainer={() =>
                        document.getElementById("email_template_container")
                      }
                    >
                      <div
                        className={`border-2 rounded-md px-4 py-2 absolute right-0 bg-white hover:bg-slate-100 ${
                          isModified.show && isModified.index === i
                            ? " bg-slate-100"
                            : null
                        }`}
                        onClick={(e) => {
                          handleModified(i);
                        }}
                      >
                        <div className="cursor-pointer flex ">
                          <Setting />
                          <span className="pl-2 text-gray-500 ">Modify</span>
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                ))}
              <DefaultFoolter orgData={orgData} user={userId} />
            </div>
          </div>
        )}
        <div className="flex justify-around my-4">
          <button
            className="bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back To Email List{" "}
          </button>
          <button
            className="bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-700"
            onClick={preview}
          >
            {" "}
            {!review ? "Preview This Email" : "Cancel Preview"}{" "}
          </button>
          {/* <button className='bg-indigo-500 text-white px-3 py-2 rounded-sm hover:bg-indigo-700' onClick={exportHTML}> {isEdit ? 'Update This Email' : 'Save This Email'} </button> */}
        </div>
      </div>
      <div className="w-[25vw] col-span-1 text-gray-500 mx-4 fixed top-20 right-0">
        <p className="px-6 py-1 border-b border-rounded-sm mb-2">{msg} </p>
        <BaseTabs
          active={activeTab}
          handleTab={handleTab}
          tabList={["Insert Content", "Email Settings", "Send Options"]}
        />
        <div className="py-3">
          {activeTab === "Insert Content" ? (
            <InsertContents
              action={handleInsert}
              set={setTemplate}
              val={template}
            />
          ) : activeTab === "Email Settings" ? (
            <EmailSettings
              setEmailSubject={setEmailSubject}
              emailSubject={emailSubject}
              style={commonStyle}
              setStyle={handleCommonStyle}
              template={template}
              update={exportHTML}
            />
          ) : (
            <SentSettings
              send={handleSendEmail}
              sendTest={handleSendTestEmail}
              setEmail={handleToSendEmail}
              email={emailList}
              contact={contact}
              sub={sub}
              setSub={setSub}
              scheduleEmail={handleScheduleEmail}
              isDisabledSchedule={isScheduling}
              isDisabledTestEmail={isSendingTestEmail}
              isDisabledSendMail={isSendingEmail}
              sendEmailCustom={(...args) =>
                handleSendEmailToCustomList(...args, "regular")
              }
              scheduleEmailCustom={(...args) =>
                handleSendEmailToCustomList(...args, "scheduled")
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EmailTemplate;
