import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { API_URL_CONSTANT } from "../constants/apiConstant";
import { getReqParamheader } from "../services/apiCall";
import ArticleComponent from "./email/ArticleComponent";
import DefaultButton from "./email/DefaultButton";
import DefaultFoolter from "./email/DefaultFoolter";
import DefaultLogo from "./email/DefaultLogo";
import EventDigest from "./email/EventDigest";
import OrgLogo from "./email/OrgLogo";
import Divider from "./email/insert/Divider";
import Heading from "./email/insert/Heading";

function EmailViewTemplate() {
  const [template, setTemplate] = useState([]);
  const [orgData, setOrgData] = useState({});
  const [review, setReview] = useState(true);
  const [emailSubject, setEmailSubject] = useState("Sample Email Subject");
  const [commonStyle, setCommonStyle] = useState({
    bg: "rgb(81 121 82)",
    bbg: "blue",
    ts: "16px",
    tf: "Arial",
    hs: "24px",
    hf: "Arial",
  });
  // const [email, setEmail] = useState("")
  const path = useLocation().pathname;
  const navigate = useNavigate();

  useEffect(() => {
    let email = path.split("/")[3];
    getByIdEmail(email);
  }, []);

  const Template = () => {
    return (
      //
      <div
        style={{
          background: commonStyle ? commonStyle.bg : "white",
          padding: "0px 10% 10%",
          color: "black",
        }}
      >
        <div style={{ background: "#ffffff", color: "black" }}>
          {template &&
            template.map((item, i) => (
              <div
                key={i}
                style={{
                  margin: "0px 16px",
                  paddingTop: i == 0 ? "12px" : "0px",
                }}
              >
                {item.type === "text" ? (
                  <div style={{ paddingBottom: "8px", textAlign: "start" }}>
                    <Heading
                      txt={item.txt}
                      hs={commonStyle.ts}
                      hf={commonStyle.tf}
                    />
                  </div>
                ) : item.type === "textH" ? (
                  <div style={{ paddingBottom: "8px", textAlign: "start" }}>
                    <Heading
                      txt={item.txt}
                      hs={commonStyle.hs}
                      hf={commonStyle.hf}
                    />
                  </div>
                ) : item.type === "image" ? (
                  <div style={{ paddingBottom: "8px" }}>
                    <OrgLogo
                      url={item?.style.logoUrl}
                      orgName={orgData?.OrganizationName}
                      logo={orgData?.logo}
                      style={{ maxWidth: "600px", height: "auto" }}
                    />
                  </div>
                ) : item?.type == "Photo" ? (
                  <div>
                    <DefaultLogo type="Photo" url={item?.data} path={path} />
                  </div>
                ) : item.type === "btn" ? (
                  <div style={{ paddingBottom: "8px" }}>
                    <DefaultButton
                      bg={commonStyle.bbg}
                      txt={item?.style.btnText}
                      align={item?.style.btnAlign}
                      link={item?.style.btnLink}
                      btnSize={item?.style.btnSize}
                    />
                  </div>
                ) : item.type === "hline" ? (
                  <div style={{ paddingBottom: "8px", width: "100%" }}>
                    <Divider />
                  </div>
                ) : item.type === "space" ? (
                  <div>
                    <br />
                  </div>
                ) : item.type == "eventdigest" ? (
                  <EventDigest
                    data={item?.data}
                    type="eventdigest"
                    id={item?.data?._id}
                    ts={commonStyle.ts}
                    tf={commonStyle.tf}
                    hs={commonStyle.hs}
                    hf={commonStyle.hf}
                  />
                ) : item.type === "event" ? (
                  <ArticleComponent
                    data={item?.data}
                    type="event"
                    ts={commonStyle.ts}
                    tf={commonStyle.tf}
                    hs={commonStyle.hs}
                    hf={commonStyle.hf}
                    read={item?.read}
                  />
                ) : (
                  <ArticleComponent
                    data={item?.data?.draft}
                    id={item?.data?._id}
                    ts={commonStyle.ts}
                    tf={commonStyle.tf}
                    hs={commonStyle.hs}
                    hf={commonStyle.hf}
                    read={item?.read}
                  />
                )}
              </div>
            ))}
          <DefaultFoolter orgData={orgData} />
        </div>
      </div>
    );
  };
  const EmailTemplate = () => {
    return (
      //style={{ background: `${commonStyle.bg}`, padding: '0px 10% 10%', color: 'black' }}
      <div>
        <div style={{ background: "#ffffff", color: "black" }}>
          {template.map((item, i) => (
            <div
              key={i}
              style={{
                margin: "0px 16px",
                paddingTop: i == 0 ? "12px" : "0px",
              }}
            >
              {item.type === "text" ? (
                <div style={{ paddingBottom: "8px", textAlign: "start" }}>
                  <Heading
                    txt={item.txt}
                    hs={commonStyle.ts}
                    hf={commonStyle.tf}
                  />
                </div>
              ) : item.type === "textH" ? (
                <div style={{ paddingBottom: "8px", textAlign: "start" }}>
                  <Heading
                    txt={item.txt}
                    hs={commonStyle.hs}
                    hf={commonStyle.hf}
                  />
                </div>
              ) : item.type === "image" ? (
                <div style={{ paddingBottom: "8px" }}>
                  <OrgLogo
                    url={item?.style.logoUrl}
                    orgName={orgData?.OrganizationName}
                    logo={orgData?.logo}
                  />
                </div>
              ) : item?.type == "Photo" ? (
                <div>
                  <DefaultLogo type="Photo" url={item?.data} path={path} />
                </div>
              ) : item.type === "btn" ? (
                <div style={{ paddingBottom: "8px" }}>
                  <DefaultButton
                    bg={commonStyle.bbg}
                    txt={item?.style.btnText}
                    align={item?.style.btnAlign}
                    link={item?.style.btnLink}
                    btnSize={item?.style.btnSize}
                  />
                </div>
              ) : item.type === "hline" ? (
                <div style={{ paddingBottom: "8px", width: "100%" }}>
                  <Divider />
                </div>
              ) : item.type === "space" ? (
                <div>
                  <br />
                </div>
              ) : item.type === "event" ? (
                <ArticleComponent
                  data={item?.data}
                  type="event"
                  ts={commonStyle.ts}
                  tf={commonStyle.tf}
                  hs={commonStyle.hs}
                  hf={commonStyle.hf}
                  read={item?.read}
                />
              ) : (
                <ArticleComponent
                  data={item?.data?.draft}
                  id={item?.data?._id}
                  ts={commonStyle.ts}
                  tf={commonStyle.tf}
                  hs={commonStyle.hs}
                  hf={commonStyle.hf}
                  read={item?.read}
                />
              )}
            </div>
          ))}
          <DefaultFoolter orgData={orgData} />
        </div>
      </div>
    );
  };

  const getByIdEmail = (id) => {
    let url = `${API_URL_CONSTANT.baseUrl}/viewemail/${id}`;
    getReqParamheader(url)
      .then((res) => {
        if (res?.status) {
          // console.log('get email template details by id response', res?.data)
          setCommonStyle(res?.data?.data?.Common_style);
          setEmailSubject(res?.data?.data?.Subject);
          setOrgData(res?.data?.data?.orgData);
          setTemplate(res?.data?.data?.template);
        } else {
          console.log("response err", res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <div
      className="w-full"
      style={{ background: commonStyle ? commonStyle.bg : "white" }}
    >
      <div
        className="flex flex-col"
        style={{ maxWidth: "800px", margin: "0 auto" }}
      >
        <ToastContainer />
        {review ? (
          <div className="text-left">
            <Template />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default EmailViewTemplate;
